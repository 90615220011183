export class StorageManager {
  public static DEFAULT_LOCALE = 'de';

  static getLocale() {
    return window.localStorage.getItem('locale') || StorageManager.DEFAULT_LOCALE;
  }

  static setLocale(locale: string) {
    window.localStorage.setItem('locale', locale);
  }
}
