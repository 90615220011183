export const en = {
  header: {
    title: 'Welcome to the CAT Europe Customer Area',
  },
  label: {
    flightStudent: 'Flight Student',
    flightSchool: 'Flight School',
    imprint: 'Imprint',
  },
  msg: {
    studentsInterface: 'If you are a flight student, please select this area to track, perform, submit, and review your assessments, progress tests, final tests, and pre-exams.',
    atoInterface: 'If you are an ATO, please select this option to manage your flight students, assign and review test submissions, and evaluate student performance metrics.',
  },
  action: {
    changeLanguage: 'Change Language',
    goToStudentsInterface: 'Go To Students Interface',
    goToAtoInterface: 'Go To ATO Interface',
  },
};
