import {Module} from 'vuex';


export interface AppState {
  isLoading: boolean,
  title: string,
  titleShort: string,
}

export const appModule: Module<AppState, any> = {
  namespaced: true,
  state: {
    isLoading: false,
    title: 'LunaTech Mentoring System',
    titleShort: 'LTMS',
  },
  mutations: {
  },
  actions: {
    showSidebar({state, commit}, show: boolean) {
      commit('SHOW_SIDEBAR', show);
    },
    startRequest({commit}) {
      commit('START_REQUEST');
    },
    stopRequest({commit}) {
      commit('STOP_REQUEST');
    },
  },
  getters: {},
};
