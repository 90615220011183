import Vue from 'vue';
import Vuex from 'vuex';
import {appModule} from './app.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: appModule,
  },
  state: {},
  mutations: {},
  actions: {},
});
