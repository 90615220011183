










import Vue from 'vue';
import LanguageChanger from '~/components/common/LanguageChanger.vue';

export default Vue.extend({
  name: 'AppToolbar',
  components: {LanguageChanger},
  computed: {
    app() {
      return this.$store.state.app;
    },
  },
  methods: {
    showSidebar() {
      this.$store.dispatch('app/showSidebar', true);
    },
  },
});
