<template>
  <v-app>
    <v-main :style="{paddingBottom: footerHeight + 'px'}">
      <AppToolbar/>
      <transition name="page" mode="out-in">
        <router-view/>
      </transition>
    </v-main>

    <!--  CAT Footer  -->
    <CatFooter ref="footer"/>
  </v-app>
</template>

<script>
import AppToolbar from './components/layout/AppToolbar.vue';
import CatFooter from './components/layout/CatFooter.vue';
import moment from 'moment';
import {StorageManager} from './util';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export default {
  name: 'app',
  components: {
    AppToolbar,
    CatFooter,
  },
  data() {
    return {
      footerHeight: 0,
    };
  },
  async created() {
    this.setLocale(StorageManager.getLocale());
    this.registerToScreenSizeChanges();
  },
  mounted() {
    this.updateFooterHeight();
  },
  methods: {
    setLocale(locale) {
      moment.locale(locale);
      this.$i18n.locale = locale;
    },
    updateFooterHeight() {
      if (this.$refs.footer.$el) {
        this.footerHeight = this.$refs?.footer?.$el?.clientHeight || this.footerHeight;
      }
    },
    registerToScreenSizeChanges() {
      fromEvent(window, 'resize')
          .pipe(debounceTime(50))
          .subscribe(() => this.updateFooterHeight());
    },
  },
};
</script>

<style lang="scss" scoped></style>
