export const de = {
  header: {
    title: 'Willkommen im CAT Europe Kundenbereich',
  },
  label: {
    flightStudent: 'Flugschüler',
    flightSchool: 'Flugschule',
    imprint: 'Impressum',
  },
  msg: {
    studentsInterface: 'Wenn du Flugschüler bist, wähle diesen Bereich um deine Prüfungen zu machen, sowie deinen Fortschritt und die Ergebnisse zu verfolgen.',
    atoInterface: 'Wenn du eine ATO vertrittst, wähle diesen Bereich um deine Flugschüler zu verwalten, Prüfungen zuzuweisen und die Ergebnisse auszuwerten.',
  },
  action: {
    changeLanguage: 'Sprache Ändern',
    goToStudentsInterface: 'Zum Studenten Interface',
    goToAtoInterface: 'Zum ATO Interface',
  },
};
